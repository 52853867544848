





















































import { Component, Mixins, Watch } from 'vue-property-decorator';

import { compareAsc, addHours } from 'date-fns';

import SplashScreen from '@/components/SplashScreen/SplashScreen.vue';
import NavMenu from '@/components/NavSidebar/NavSidebar.vue';
import ModalWelcome from '@/components/Modal/ModalWelcome/ModalWelcome.vue';
import BannerPromotions from '@/components/BannerPromotions/BannerPromotions.vue';
import BannerRenewPlan from '@/components/BannerRenewPlan/BannerRenewPlan.vue';
import FloatingButton from '@/components/FloatingButtons/index.vue';

import Layout from '@/mixins/Layout';

interface Permission {
  ID: number;
  end_date: string | Date;
  start_date: string | Date;
  isExtraPermission?: boolean;
  name: string;
}

@Component({
  components: {
    SplashScreen,
    NavMenu,
    ModalWelcome,
    BannerPromotions,
    BannerRenewPlan,
    FloatingButton
  }
})
export default class SidebarLayout extends Mixins(Layout) {
  get getSidebar() {
    return this.$store.getters.sidebar;
  }

  get getDecreaseSidebar() {
    return this.$store.getters.decreaseSidebar;
  }

  @Watch('$sidebar.contentInstance')
  onInstance() {
    this.removeAllChildNodes(this.$refs.sidebarcontent);
    // @ts-ignore
    this.$refs.sidebarcontent.appendChild(this.$sidebar.contentInstance.$el);
  }

  isFirstPermissionTemporaryOver() {
    const permissions = this.$store.getters.profile?.permissions ?? [];

    if (!permissions.length) return false;

    const foundPermission = permissions
      .sort((permissionFirst: Permission, permissionSecond: Permission) => {
        const permissionFirstDate = new Date(permissionFirst?.start_date);
        const permissionSecondDate = new Date(permissionSecond?.start_date);

        return permissionFirstDate.getTime() - permissionSecondDate.getTime();
      })
      .find((permission: Permission) => permission?.isExtraPermission);

    const isOverPermission = compareAsc(addHours(new Date(foundPermission?.end_date), 3), new Date()) === -1;

    return foundPermission ? isOverPermission : false;
  }
}
